<template>

  <div class="p-4 w-full max-w-sm bg-white rounded-lg border border-alice-200 shadow-md sm:p-6 md:p-8 dark:bg-alice-800 dark:border-alice-700 mx-auto">
      <form class="space-y-6" action="#">
          <h5 class="text-md font-small text-alice-900 dark:text-white">Provide the password to view the gallery</h5>
          <div>
              <label for="password" class="block mb-2 text-sm font-medium text-alice-900 dark:text-alice-300">Password</label>
              <input v-model="password" type="password" name="password" id="password" placeholder="••••••••" class="bg-alice-50 border border-alice-300 text-alice-900 text-sm rounded-lg focus:ring-alice-500 focus:border-alice-500 block w-full p-2.5 dark:bg-alice-600 dark:border-alice-500 dark:placeholder-alice-400 dark:text-white" required>
          </div>
          <button @click.prevent="$emit('submit', password)" class="w-full text-white bg-alice-600 hover:bg-alice-700 focus:ring-4 focus:outline-none focus:ring-alice-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-alice-600 dark:hover:bg-alice-700 dark:focus:ring-alice-800">Authenticate</button>
      </form>
  </div>

</template>

<script>
export default {

  name: 'PasswordForm',

  data() {

    return {
			password: '',
    }
  },
}
</script>

<style lang="scss" scoped>
  
</style>