<template>
	<div v-if="layout">
		<div class="grid grid-rows-2 grid-cols-4 grid-flow-col lg:gap-4 md:gap-2 gap-1 lg:mb-4 md:mb-2 mb-1 h-[600px]" v-for="row in layout.rows" :key="row.id">
			
			<div :class="`row-span-${column.rowspan} col-span-${column.colspan} relative`" v-for="column in row.columns" :key="column.id">
				<ix-picture
					@click="triggerLightbox(column.image.id)"
					class="drop-shadow-lg"
				>
					<ix-source :src="column.image.path" media="(min-width: 768px)" :imgixParams="{ ar: '2:1' }"/> 
					<ix-source :src="column.image.path" media="(min-width: 320px)" :imgixParams="{ ar: '1.5:1' }" /> 
					<ix-img class="object-cover object-center mx-auto h-full w-full cursor-pointer" :src="column.image.path" :imgixParams="{ w: 100, ar: '3:1' }" />
				</ix-picture>

				<div v-if="preview" class="absolute bottom-0 w-full bg-alice-300 p-2" :class="{ 'bg-honey-500': isSelected(column.image.id) }">
					<button
						:disabled="disableUnselected && !isSelected(column.image.id)"
						:class="{ disabled: disableUnselected && !isSelected(column.image.id) }"
						@click.prevent="$emit('selected', column.image.id)"
						class="w-full text-white bg-alice-600 hover:bg-alice-700 focus:ring-1 focus:outline-none focus:ring-alice-300 font-medium rounded-sm text-sm px-5 py-1 text-center dark:bg-alice-600 dark:hover:bg-alice-700 dark:focus:ring-alice-800"
					>
						{{ isSelected(column.image.id) ? 'Unselect' : 'Select' }}
					</button>
				</div>

				<div v-if="!preview && isSelected(column.image.id)" class="absolute bottom-0 w-full bg-alice-300 p-2 text-center text-alice-800">
					<span>Selected</span>
				</div>

				<div v-if="download" class="absolute bottom-0 w-full bg-alice-300 p-2 text-center text-alice-800 cursor-pointer" @click="downloadImage(column.image.id)">
					<span>Download</span>
				</div>

			</div>

		</div>

		<VueEasyLightbox
			:visible="lightboxVisible"
			:imgs="sources"
			:index="currentIndex"
			@hide="onLightboxHide"
			:loop="true"
			:moveDisabled="true"
		>
			<template v-slot:toolbar="{}">
			</template>
		</VueEasyLightbox>

	</div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { buildUrl } from '@imgix/vue'
import api from '@/services/api'

export default {

	name: 'PhotoGallery',

	components: {
		VueEasyLightbox,
	},

	props: {
		layout: Object,
		preview: Boolean,
		download: Boolean,
		selected: Array,
		target: Number,
	},

	data() {

		return {
			lightboxVisible: false,
			currentIndex: 0,
			windowHeight: window.innerHeight,
			windowWidth: window.innerWidth,
			minWidth: 1000,
			minHeight: 1000,
		}
	},

	computed: {

		sources() {
			const params = {
				auto: 'format',
				w: this.windowWidth > this.minWidth ? this.windowWidth : this.minWidth,
				h: this.windowHeight > this.minHeight ? this.windowHeight : this.minHeight,
			}
			const sources = []
			this.layout.rows.forEach( row => row.columns.forEach( column => sources.push( buildUrl(column.image.path, params) ) ) )
			return sources
		},

		images() {
			const images = []
			this.layout.rows.forEach( row => row.columns.forEach( column => images.push( column.image ) ) )
			return images
		},

		disableUnselected() {
			return this.selected.length == this.target
		},

	},

	methods: {

		triggerLightbox(imageId) {
			const index = this.images.findIndex( i => i.id == imageId)
			this.currentIndex = index
			this.lightboxVisible = true
		},

		onLightboxHide() {
			this.lightboxVisible = false
		},

		onResize() {
			this.windowWidth = window.innerWidth
			this.windowHeight = window.innerHeight
		},

		isSelected(imageId) {
			return this.selected.includes(imageId)
		},

		downloadImage(imageId) {
			api.gallery.download({
				galleryId: this.$route.params.galleryId,
				imageId: imageId
			}).then( response => {
				window.open(response.data.download_url, "_blank")
			}).catch( error => {
				console.log(error)
			})
		},
	},
	
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		})
	},

	beforeUnmount() { 
		window.removeEventListener('resize', this.onResize); 
	},

}
</script>

<style lang="css" scoped>
	
</style>