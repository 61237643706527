import http from '@/services/app-http-driver'

export default {
  gallery: {
    password (data = {}) {
      return http({ url: `/gallery/password`, method: 'post', data: data })
    },
    authenticate (data = {}) {
      return http({ url: `/gallery/authenticate`, method: 'post', data: data })
    },
    submitSelection (data = {}) {
      return http({ url: `/gallery/submit-selection`, method: 'post', data: data })
    },
    show (data = {}) {
      return http({ url: `/gallery/show`, method: 'post', data: data })
    },
    download (data = {}) {
      return http({ url: `/gallery/download`, method: 'post', data: data })
    },
  },
}
