<template>

  <div class="gallery-page p-1">
    
    <PasswordForm v-if="passwordRequired" @submit="authenticate" :error="passwordError"/>

    <div v-if="gallery">

      <GalleryPreviewNotice v-if="showPreview" :submission="allowSubmission" :target="gallery.final_target"/>

      <div class="text-center mt-8 mb-8 text-alice-600">
        
        <h3 class="text-5xl">{{ gallery.title }}</h3>

        <p class="text-xl">{{ gallery.description }}</p>
        
      </div>
      
      <div class=" w-1/4 mx-auto py-4" v-if="allowSubmission">
        <SubmitSelectionButton :disabled="disableImageSelection" @submit="submitSelection"/>
      </div>

      <PhotoGallery
        v-if="gallery"
        :layout="gallery.layout"
        @selected="handleImagesSelected"
        :target="gallery.final_target"
        :selected="imageIds"
        :preview="allowSubmission"
        :download="allowDownload"
      />

    
    </div>
    
  </div>

</template>

<script>

import api from '@/services/api'
import PasswordForm from '@/components/PasswordForm'
import PhotoGallery from '@/components/PhotoGallery'
import GalleryPreviewNotice from '@/components/GalleryPreviewNotice'
import SubmitSelectionButton from '@/components/SubmitSelectionButton'

export default {

  name: 'Gallery',

  components: {
    PasswordForm,
    PhotoGallery,
    GalleryPreviewNotice,
    SubmitSelectionButton,
  },

  data() {

    return {
      passwordRequired: false,
      passwordError: '',
      gallery: null,
      selectedImageIds: [],
    }
  },

  computed: {
    
    showPreview() {
      return this.gallery && this.gallery.type == 'preview'
    },

    allowDownload() {
      return this.gallery && this.gallery.type == 'finale'
    },

    disableImageSelection() {
      return this.selectedImageIds.length != this.gallery.final_target
    },

    allowSubmission() {
      return this.showPreview && !this.gallery.preview_selection
    },

    imageIds() {
      return this.showPreview && !this.allowSubmission
        ? this.gallery.preview_selection.images.map( i => i.id )
        : this.selectedImageIds 
    },
  },

  methods: {

    checkForPassword() {

      api.gallery.password({
        galleryId: this.$route.params.galleryId
      }).then( response => {
        if (response.data.requires_password) {
          this.passwordRequired = true
        } else {
          this.fetchGallery()
        }
      }).catch( () => {
        this.$router.push({name: 'Home'})
      })
    },

    fetchGallery() {

      api.gallery.show({
        galleryId: this.$route.params.galleryId
      }).then( response => {
        this.gallery = response.data.gallery
        /* send event to change the background color */
        this.emitter.emit('background-change', { background: this.gallery.layout.background })
      })
    },

    authenticate(password) {

      api.gallery.authenticate({
        galleryId: this.$route.params.galleryId,
        password: password,
      }).then( () => {
        this.passwordRequired = false
        this.fetchGallery()
      }).catch( () => {
        this.$router.push({name: 'Home'})
      })
    },

    submitSelection() {

      api.gallery.submitSelection({
        galleryId: this.$route.params.galleryId,
        imageIds: this.selectedImageIds,
      }).then( () => {
        this.fetchGallery()
      }).catch( (error) => {
        console.log(error)
      })
    },

    handleImagesSelected(selection) {
      if (this.selectedImageIds.includes(selection)) {
        this.selectedImageIds.splice(this.selectedImageIds.indexOf(selection), 1)
      } else {
        this.selectedImageIds.push(selection)
      }
    },

  },

  mounted() {

    this.checkForPassword()

  },
}
</script>

<style lang="scss" scoped>
  
</style>