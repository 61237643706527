<template>

	<div>
		<button
			:disabled="disabled"
			:class="{ disabled: disabled, 'text-alice-900 hover:bg-alice-700 hover:text-alice-50 ring-alice-600': !disabled }"
			@click.prevent="$emit('submit')"
			class="w-full text-alice-600 bg-alice-50 ring-1 ring-alice-300 focus:outline-none focus:ring-alice-300 font-medium rounded-sm text-md px-5 py-2 text-center dark:bg-alice-600 dark:hover:bg-alice-700 dark:focus:ring-alice-800"
		>
			Submit selection
		</button>
	</div>

</template>

<script>
export default {

	name: 'SubmitSelectionButton',

	props: {
		disabled: Boolean,
	},

	data() {

		return {}
	},

	computed: {

	},

	methods: {

	},
}
</script>
