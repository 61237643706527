<template>

	<div class="bg-alice-800 text-alice-50 p-4 rounded">
		<p v-if="submission">
			This is a <strong>preview</strong> of the final gallery and all images are <strong>watermarked</strong>.
			Please choose <strong>{{ target }} photograph{{ target > 1 ? 's' : '' }}</strong>, submit your selection and you will receive access to a gallery with your chosen high-res photographs with ability to download them for printing.
		</p>
		<p v-else>
			This is a <strong>preview</strong> of the final gallery and all images are <strong>watermarked</strong>. Thank you for making the selection, you will receive access to a gallery with your chosen high-res photographs soon.
		</p>
	</div>

</template>

<script>
export default {

	name: 'GalleryPreviewNotice',

	props: {
		target: Number,
		submission: Boolean,
	},

	data() {

		return {}
	},

	computed: {

	},

	methods: {

	},
}
</script>

<style lang="scss" scoped>
	
</style>